<template>
  <div v-loading="konwledgeLoading" class="container">
    <header class="konwledge-details-header">
      <div style="width: 124px;height:30px" @click="goPagehome">
        <img src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/c835df6586429c047eba1f7268a268c4.png" style="width: 100%; height:100%">
      </div>
      <div class="gologin">
        <div class="login" @click="goLogin">
          登录
        </div>
        <div @click="goPagehome">
          飞椽云介绍
        </div>
      </div>
    </header>
    <body class="konwledge-details-container">
      <aside class="left-sider" :class="{ 'is-active': expend }" :style="{ width: siderWidth }" v-loading="treeDataLoading">
      <div class="expand-switch" :class="{ 'is-open': expend }" @click="expend = !expend">
        <svg class="expand-icon">
          <use xlink:href="#iconshuangjiantou_shang" />
        </svg>
      </div>
      <PageTree
        v-if="!treeDataLoading"
        :treeData="treeData" 
        :current="currentData"
        v-model="activeID"
        :knowledgeDetails="knowledgeDetails"
        @change="handleChange"
      />
    </aside>
    <section class="right-sider" v-loading="currentDataLoading">
      <RightSider 
        :current="currentData"
      />
    </section>
    </body>

  </div>
</template>
<script>
import PageTree from './components/PageTree.vue'
import RightSider from './components/RightSider.vue'
import Router from '@/router'
import { defineComponent, ref, onMounted, computed } from 'vue'
import { officialKileDetails, repositoryPage } from '@/saas-apis/konwledgeBase.js'
import { Message, Empty } from 'element-ui'
export default defineComponent({
  components: {
    PageTree,
    RightSider,
    'el-empty': Empty,
  },
  setup() {
    const knowledgeId = ref('')
    const expend = ref(true)
    const siderWidth = computed(() => {
      return expend.value ? '' : '0px'
    })
    const knowledgeDetails = ref(null)
    const konwledgeLoading = ref(false)
    const getknowledgeDetails = () => {
      // const reqData = {
      //   repository_id: knowledgeId.value
      // }
      konwledgeLoading.value = true
      repositoryPage(Router.currentRoute.query.uuid,key.value).then(res => {
        if(res.data.code === 200) {
          knowledgeDetails.value = res.data.data
          treeData.value = res.data.data
          currentData.value = res.data.data[0]
          handleChange(res.data.data[0].id)
        }
      }).catch((res) => {
        Message.error(res.data.msg)
      }).finally(() => {
        konwledgeLoading.value = false
      })
    }
    
    const treeData = ref([])
    const treeDataLoading = ref(false)
    // const getTreeData = () => {
    //   const reqData = {
    //     repository_id: knowledgeId.value,
    //   }
    //   treeDataLoading.value = true
    //   knowledgePageWebSite.get(reqData).then(res => {
    //     if(res.data.code === 200) {
    //       treeData.value = res.data.data
    //       if(!activeID.value) {
    //         if(res.data.data.length) {
    //           handleChange(res.data.data[0].id)
    //         }
    //       }
    //     }
    //   }).catch((res) => {
    //     Message.error(res.data.msg)
    //   }).finally(() => {
    //     treeDataLoading.value = false
    //   })
    // }
    
    const currentData = ref(null)
    const currentDataLoading = ref(false)
    const activeID = ref('')
    // activeID change
    const handleChange = (id) => {
      activeID.value = id
      if(!id) {
        currentData.value = knowledgeDetails.value
        return
      }
      const reqData = {
        repository_id: knowledgeId.value,
        page_id: id,
      }
      currentDataLoading.value = true
      officialKileDetails(reqData).then(res => {
        if(res.data.code === 200) {
          currentData.value = res.data.data
        }
      }).catch((res) => {
        Message.error(res.data.msg)
      }).finally(() => {
        currentDataLoading.value = false
      })
    }
    const key = ref(null)
    const goLogin = () =>{
      window.location.href = 'https://saas.bimcc.com/login';
    }
    const goPagehome = () =>{
      window.location.href = 'https://www.bimcc.com/web-page/home';
    }
    onMounted(() => {
      if(Router.currentRoute.query.uuid){
        let pageInfo =JSON.parse(localStorage.getItem('pageInfo'))
        if(pageInfo){
          pageInfo.forEach(item=>{
            if(Router.currentRoute.query.uuid ===item.uuid){
              key.value = item.key
              knowledgeId.value = item.repository_id
            }
          })
        }

        if(knowledgeId.value){
          handleChange(knowledgeId.value)
          getknowledgeDetails()
          // getTreeData()
        }
        
      }
    })
    return {
      goLogin,
      goPagehome,
      expend,
      siderWidth,
      treeData,
      handleChange,
      currentData,
      konwledgeLoading,
      treeDataLoading,
      knowledgeDetails,
      currentDataLoading,
      activeID,
      knowledgeId
    }
  },
})
</script>
<style lang="less" scoped>
.container{
  width: 100%;
  height: 100%;
  background: #F4F6FA;
  display: flex;
  flex-direction: column;
  .konwledge-details-header{
    width:100%;
    height: 56px;
    background: #FFFFFF;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 9px 16px;
    box-sizing: border-box;
    .gologin{
      display: flex;
      color: #161C1F;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      .login{
        margin-right: 24px;
      }
    }
  }
  .konwledge-details-container{
    position: relative;
    width: 100%;
    // height: 100%;
    display: flex;
    text-align: left;
    background: #F4F6FA;
    box-shadow: inset -1px 0px 0px #EFF1F5;
    border-radius: 8px 0px 0px 8px;
    padding:16px;
    box-sizing: border-box;
    .left-sider{
      width: 300px;
      flex-shrink: 0;
      position: relative;
       height: #fff;

      transition: width .5s;
      @media all and (max-width: 1300px) {
        & {
          position: absolute;
          left: 0;
          top: 0;
          z-index: 100;
          height: 100%;
          background: #fff;
          box-shadow: 0px 2px 4px 2px #ddd;
        } 
      }
      .expand-switch{
        position: absolute;
        top: 40px;
        right: 0;
        z-index: 1;
        width: 30px;
        height: 30px;
        transform: translateX(100%);
        transition: all .5s;
        box-shadow: 2px 2px 5px 3px #ccc;
        border-radius: 0 50% 50% 0;
        background: #fff;
        .expand-icon{
          width: 100%;
          height: 100%;
          transform: rotate(90deg);
          transition: all .5s;
        }
      }
      .is-open{
        width: 25px;
        height: 25px;
        transform: translateX(50%);
        border-radius: 50%;
        visibility: hidden;
        .expand-icon{
          transform: rotate(-90deg);
          visibility: hidden;
        }
      }
      &:hover{
        .is-open{
          transition: all .5s;
          visibility: visible;
          .expand-icon{
            visibility: visible;
          }
        }
      }
    }
    .is-active{
      border-right: 1px solid #f1f1f1;
    }
    .right-sider{
      background: #fff;
      flex-grow: 1;
      width: 10px;
      padding: 0 20px;
      box-sizing: border-box;
      border-radius: 0px 8px 8px 0px;
    }
  }
}

</style>
