<!--
 * @Author: 朱廷果 1028509503@qq.com
 * @Date: 2023-01-13 15:30:06
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2023-01-18 15:08:57
 * @FilePath: \dataview-next\src\manage-views\components\share\knowledge\components\RichTextViewer.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="rich-text-viewer-container">
    <div class="rich-text-warp">
      <Shower :value="text" />
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import Shower from './AutoShower.vue'
// import Comment from '@/manage-views/views/knowledgeBase/components/comment/index.vue'
import { Empty, Popover } from 'element-ui'
export default defineComponent({
  props: {
    text: {
      type: String,
      default: ''
    },
     data: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  components: {
    Shower,
    'el-empty': Empty,
    'el-popover': Popover,
    // Comment
  },
  setup() {
    const operationTypeHash = {
      show: {
        icon: 'iconbimgis_xianshi',
        label: '查看'
      },
      add: {
        icon: 'iconxinzeng1',
        label: '新增'
      },
      edit: {
        icon: 'iconbianji1',
        label: '编辑'
      },
    }
    return {
      operationTypeHash
    }
  },
})
</script>
<style lang="less" scoped>
.rich-text-viewer-container{
  width: 100%;
  height: 100%;
  overflow-y: auto;
  .rich-text-warp{
    height: 100%;
  }
  .file-info-warp{
    display: flex;
    align-items: center;
    margin-top: 10px;
    padding: 10px;
    .item{
      width: auto;
      & + .item {
        margin-left: 16px;
      }
    }
  }
  .dynamic-warp{
    margin-top: 12px;
    padding-bottom: 10px;
    border-top: 1px solid #f1f1f1;
  }
}
</style>