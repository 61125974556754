<template>
  <div class="left-sider-container">
    <section class="left-sider-warp">
      <div class="filelength">共{{treeData.length}}个文件</div>
      <el-tree
        :ref="(el) => { treeRef = el }"
        v-if="Array.isArray(treeData) && treeData.length"
        :data="treeData"
        default-expand-all
        :expand-on-click-node="false"
        node-key="id"
        :filter-node-method="filterNode"
        @node-click="handleNodeClick"
      >
        <template #default="{ data }">
          <div class="custom-tree-node" :class="getDataClass(data)">
            <div class="node-label">
              <div class="icon">
                <svg  class="action-icon" aria-hidden="true" width="16" height="16">
                  <use :xlink:href="getFileSvgIcon(data)" />
                </svg>
              </div>
              <div class="label">
                {{ data.name }}
              </div>
            </div>
          </div>
        </template>
      </el-tree>
      <el-empty v-else description="暂无内容"></el-empty>
    </section>

  </div>
</template>
<script>
import { defineComponent, ref, watch, onMounted, nextTick } from 'vue'
import { Dropdown, DropdownMenu, DropdownItem, Empty, Tree } from 'element-ui'

export default defineComponent({
  components: {
    'el-dropdown': Dropdown,
    'el-dropdown-menu': DropdownMenu,
    'el-dropdown-item': DropdownItem,
    'el-empty': Empty,
    'el-tree': Tree,
  },
  props: {
    knowledgeDetails: {
      type: Array,
      default() {
        return {}
      }
    },
    treeData: {
      type: Array,
      default() {
        return []
      }
    },
    value: {
      type: [String, Number],
      default: ''
    },
    current: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  setup(props, ctx) {
    const searchShow = ref(false)
    const searchVal = ref('')
    const treeRef = ref(null)
    const handleNodeClick = (data) => {
      // if(data.type !== 'folder') {
      //   treeRef.value?.setCurrentKey(data.id)
      //   ctx.emit('input', data.id)
      //   ctx.emit('change', data.id)
      // }
      if(data.type) {
        treeRef.value?.setCurrentKey(data.id)
        ctx.emit('input', data.id)
        ctx.emit('change', data.id)
      }
    }
    const fileTypeHash = {
      'jpg':'#icontupianzhaopian',
      'png':'#icontupianzhaopian',
      'bmp':'#icontupianzhaopian',
      'gif':'#icontupianzhaopian',
      'jpeg':'#icontupianzhaopian',
      'cdr':'#icontupianzhaopian',
      'psd':'#icontupianzhaopian',
      'doc':'#icona-wenjianwendang',
      'docx':'#icona-wenjianwendang',
      'ppt':'#iconppt',
      'pptx': '#iconppt',
      'pdf':'#iconpdf',
      'xlsx':'#iconbiaoge',
      'xlsm':'#iconbiaoge',
      'xls':'#iconbiaoge',
      'csv':'#iconbiaoge',
      'txt': '#icontxt',
      'mp3': '#iconyinpin',
      'wav': '#iconyinpin',
      'mp4': '#iconshipin1',
      'webm': '#iconshipin1',
      'ogg': '#iconshipin1',
    }
    const getFileSvgIcon = (item) => {
      const { type } = item
      if (type === 'word') {
        return '#iconcaogaowenjian'
      } else if (type === 'file') {
        const ext = item?.details?.path.substring(item?.details?.path.length-8,item?.details?.path.length).split('.')[1];
        return fileTypeHash[ext.toLocaleLowerCase()] || '#iconweizhiwenjian'
      } else if (type === 'folder') {
        return '#iconwenjianjia3'
      }
      return '#iconweizhiwenjian'
    }

    let timer = null
    const handleSearch = (val) => {
      if(treeRef.value) {
        if(timer) {
          clearTimeout(timer)
        }
        timer = setTimeout(() => {
          treeRef.value.filter(val)
          timer = null
        }, 1000)
      }
    }
    const filterNode = (value, data) => {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    }
    const getDataClass = (data) => {
      if (!props.current && !props.current.id) {
        return ''
      }
      const className = data.id === props.current.id ? 'selected' : ''
      nextTick(() => {
        const allDom = document.getElementsByClassName('el-tree-node__content');
        allDom.forEach((element) => {
          let boo = false
          if (element?.children?.length) {
            element.children.forEach((item) => {
							if (item._prevClass.indexOf('selected') !== -1) {
								element.style.background = '#337EFF'
								element.style.borderRadius = '6px';
                boo = true
							} else {
                element.style.background = '#fff'
              }
            })
          }
          if (boo) {
            element.onmouseover = function () {
              element.style.background = '#337EFF';
            };
            element.onmouseleave = function () {
              element.style.background = '#337EFF';
            };
          } else {
						element.style.background = '#fff';
						element.style.backgroundColor = '#fff';
						element.onmouseover = function () {
							element.style.background = '#F2F4F7';
              element.style.borderRadius = '6px';
						};
						element.onmouseleave = function () {
							element.style.background = '#fff';
						};
          }
        })
      })
      return className;
    }
    watch(() => props.value, (n) => {
      if(n && treeRef.value) {
        treeRef.value?.setCurrentKey(n)
      }
    })
    watch(() => searchVal.value, (n) => {
      handleSearch(n)
    })
    onMounted(() => {
      if(props.value && treeRef.value) {
        treeRef.value?.setCurrentKey(props.value)
      }
    })
    return {
      searchShow,
      searchVal,
      handleNodeClick,
      getFileSvgIcon,
      treeRef,
      filterNode,
      getDataClass
    }
  },
})
</script>
<style lang="less" scoped>
@activeColor: #409eff;
  .left-sider-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background: #fff;
    border-radius: 8px 0px 0px 8px;
    .left-sider-header{
      flex-shrink: 0;
      .title{
        display: flex;
        align-items: center;
        width: 100%;
        padding: 16px 16px;
        box-sizing: border-box;
        .title-text{
          flex-grow: 1;
          width: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 18px;
          font-weight: 600;
          text-align: left;
          cursor: pointer;
        }
      }
      .action{
        position: relative;
        display: flex;
        height: 30px;
        align-items: center;
        justify-content: space-between;
        padding: 10px 16px;
        border-top: 1px solid #f1f1f1;
        .left-action{
          flex-grow: 0;
          display: flex;
        }
        .right-action{
          flex-grow: 0;
          display: flex;
        }
        .action-item{
          display: flex;
          align-items: center;
          padding: 5px;
          border-radius: 5px;
          &:hover{
            background: #f5f5f5;
          }
          & + .action-item{
            margin-left: 10px;
          }
        }
        .search{
          display: flex;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          align-items: center;
          padding: 5px;
          box-sizing: border-box;
          background: #fff;
          .search-input{
            flex-grow: 1;
          }
          .search-close{
            margin: 0 5px;
            padding: 5px;
            box-sizing: border-box;
            border-radius: 50%;
            &:hover{
              background: #f1f1f1;
            }
          }
        }
      }
    }
    .left-sider-warp{
      flex-grow: 1;
      height: 10px;
      overflow-y: auto;
      margin:16px 12px 0;
      .filelength{
        padding-left: 12px;
        color: #161C1F;
        margin-bottom: 7px;
      }
      :deep(.el-tree-node__expand-icon) {
        font-size: 14px;
      }
      :deep(.el-tree-node__content) {
        width: 100%;
        height: 32px;
        margin-bottom: 4px;
        box-sizing: border-box;
      }
      :deep(.is-current) {
        & > .el-tree-node__content > .custom-tree-node > .node-label{
          color: @activeColor;
        }
      }
      .custom-tree-node{
        flex-grow: 1;
        width: 10px;
        height: 32px;
        display: flex;
        .node-label{
          height: 100%;
          flex-grow: 1;
          width: 10px;
          display: flex;
          align-items: center;
          .icon{
            flex-shrink: 0;
            margin-right: 8px;
            height: 16px;
          }
          .label{
            padding: 3px 0;
            flex-grow: 1;
            width: 10px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
      .selected {
        .node-label {
          color: #fff !important;
          .label {
            color: #fff !important;
          }
        }
      }
    }
  }
</style>