<template>
  <div class="flie-viewer-container">
    <div class="file-viewer-warp">
      <div class="file-info-warp">
      </div>
      <!-- 图片 -->
      <template v-if="fileType === 'img'">
        <!-- <img :src="url" alt="" style="width:100%;height:100%"> -->
        <el-image 
          style="max-width: 100%; height: auto"
          :src="url"
          fit="scale-down"
          :preview-src-list="[url]">
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
      </template>

      <template v-else-if="fileType === 'doc'">
 
        <iframe
          class="office-warp"
          :src="url.startsWith('https')? `https://weboffice.bimcc.net?ssl=1&furl=${url}` : `https://weboffice.bimcc.net?furl=${url}`"
        >
        </iframe>
      </template>
      <template v-else-if="fileType === 'mp3'">
        <audio :src="url" controls></audio>
      </template>
      <template v-else-if="fileType === 'mp4'">
        <video style="width: 100%; height: auto" :src="url" controls></video>
      </template>
      <el-empty v-else description="暂不支持预览该模式文件" />
    </div>
    <!-- <div class="dynamic-warp">
      <Comment :data="data"/>
    </div> -->
  </div>
</template>
<script>
import { defineComponent, computed } from 'vue'
import { Image, Empty, Popover } from 'element-ui'
// import Comment from '@/manage-views/views/knowledgeBase/components/comment/index.vue'
export default defineComponent({
  components: {
    'el-image': Image,
    'el-empty': Empty,
    // Comment,
    'el-popover': Popover
  },
  props: {
    data: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  setup(props) {
    const url = computed(() => {
      return props.data.detail.path
    })
    const fileTypeHash = {
      'bmp': 'img',
      'jpg': 'img',
      'png': 'img',
      'gif': 'img',
      'jpeg': 'img',
      'cdr': 'img',
      'psd': 'img',
      'doc': 'doc',
      'docx': 'doc',
      'pdf': 'doc',
      'ppt': 'doc',
      'pptx': 'doc',
      'xlsx': 'doc',
      'xls': 'doc',
      'txt': 'doc',
      'mp3': 'mp3',
      'wav': 'mp3',
      'mp4': 'mp4',
      'webm': 'mp4',
      'ogg': 'mp4'
    }
    const fileType = computed(() => {
      if(!props.data.detail || !props.data.detail.path) return 
      const arr = props.data.detail.path.split('.')
      const pre = arr[arr.length - 1]
      return fileTypeHash[pre.toLocaleLowerCase()]
    })
    const operationTypeHash = {
      show: {
        icon: 'iconbimgis_xianshi',
        label: '查看'
      },
      add: {
        icon: 'iconxinzeng1',
        label: '新增'
      },
      edit: {
        icon: 'iconbianji1',
        label: '编辑'
      }
    }
    return {
      fileType,
      url,
      operationTypeHash
    }
  },
})
</script>
<style lang="less" scoped>
@fileViewHeight: 70vh;
.flie-viewer-container{
  width: 100%;
  height: 100%;
  overflow: auto;
  .file-viewer-warp{
    width: 100%;
    height: 100%;
    // min-height: 200px;
    // max-height: @fileViewHeight;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    background: #fff;
    .image-slot{
      font-size: 1.5em;
    }
    .office-warp{
      width: 100%;
      height: @fileViewHeight;
      border: none
    }
  }
  .file-info-warp{
    display: flex;
    align-items: center;
    padding: 10px;
    background: #fff;
    .item{
      width: auto;
      & + .item {
        margin-left: 16px;
      }
    }
  }
  .dynamic-warp{
    margin-top: 12px;
    border-top: 1px solid #f1f1f1;
    .type-title{
      padding: 10px;
      font-size: 1.5em;
    }
  }
}
</style>