<template>
  <div class="right-sider-container">
    <div class="right-sider-header">
      <div class="header-title">
        <h1>{{ current.name }}</h1>
        <div class="current-info">
          更新时间: {{ current.updated_at }} &nbsp; 阅读量:{{ current.view }}
        </div>
      </div>
      <template v-if="current && current.type === 'file'">
          <el-tooltip effect="dark" content="全屏">
            <el-button @click.stop="hanleFull" type="text">
              <svg class="action-icon" aria-hidden="true" width="18" height="18">
                <use xlink:href="#iconquanping3" fill="#409eff" />
              </svg>
            </el-button>
          </el-tooltip>
        </template>
      </div>
    <div class="right-sider-warp">
      <KnowledgeViewer v-if="current && !current.type" :data="current" />
      <RichTextViewer v-if="current && current.type === 'word'" :data="current" :text="wordText" />
      <FolderViewer v-if="current && current.type === 'folder'" :data="current"/>
      <FileViewer v-if="current && current.type === 'file'" :data="current" />
    </div>
    <input type="text" id="copyInput">
  </div>
</template>
<script>
import { defineComponent, computed } from 'vue'
import RichTextViewer from './RichTextViewer'
import KnowledgeViewer from './KnowledgeViewer.vue'
import FolderViewer from './FolderViewer.vue'
import FileViewer from './FileViewer.vue'
import Router from '@/router'
import { Message,Tooltip } from 'element-ui'


export default defineComponent({
  props: {
    current: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  components: {
    RichTextViewer,
    KnowledgeViewer,
    FolderViewer,
    FileViewer,
    'el-tooltip': Tooltip,
  },
  setup(props, ctx) {
    const wordText = computed(() => {
      if(props.current && props.current.detail) {
        return props.current.detail.content || ''
      } else {
        return ''
      }
    })
    const hanleEditor = () => {
      Router.push({
        name: 'KnowledgeEditor',
        params: {
          id: props.current.id
        }
      })
    }
    const handleCommand = (command, data = null) => {
      ctx.emit('handleCommand', command, data)
    }
    const handleChange = (res) => {
      ctx.emit('change', res)
    }
    const handlePathChange = (res) => {
      ctx.emit('change', res.id)
    }
    const hanleFull = () => {
      let url = props.current.detail.path
      window.open(url.startsWith('https')? `https://weboffice.bimcc.net?ssl=1&furl=${url}` : `https://weboffice.bimcc.net?furl=${url}`)
    }
    const hanleDown = () => {
      if(props.current.detail.path) {
        let a = document.createElement('a')
        a.href = props.current.detail.path
        a.setAttribute('target', '_blank')
        a.setAttribute('download', props.current.name)
        a.click()
        // 移除a的引用
        setTimeout(() => {
          a = null
        }, 100)
      }
    }
    const hanleBack = () => {
      Router.back()
    }
    const doCopy = () => {
      const input = document.getElementById('copyInput')
      input.value = `${window.location.protocol}//${window.location.host}${Router.currentRoute.path}?pageId=${props.current.id}`
      input.select()
      document.execCommand('copy')
      Message.success('复制成功!')
    }
    const fileTypeHash = {
      'jpg':'#icontupianzhaopian',
      'png':'#icontupianzhaopian',
      'bmp':'#icontupianzhaopian',
      'gif':'#icontupianzhaopian',
      'jpeg':'#icontupianzhaopian',
      'cdr':'#icontupianzhaopian',
      'psd':'#icontupianzhaopian',
      'doc':'#icona-wenjianwendang',
      'docx':'#icona-wenjianwendang',
      'ppt':'#iconppt',
      'pptx': '#iconppt',
      'pdf':'#iconpdf',
      'xlsx':'#iconbiaoge',
      'xlsm':'#iconbiaoge',
      'xls':'#iconbiaoge',
      'csv':'#iconbiaoge',
      'txt': '#icontxt',
      'mp3': '#iconyinpin',
      'wav': '#iconyinpin',
      'mp4': '#iconshipin1',
      'webm': '#iconshipin1',
      'ogg': '#iconshipin1',
    }
    const getFileSvgIcon = (item) => {
      console.log(item)
      if(item){
        const { type } = item
        if (type === 'word') {
        return '#iconcaogaowenjian'
      } else if (type === 'file') {
        const ext = item?.detail?.path.substring(item?.detail?.path.length-8,item?.detail?.path.length).split('.')[1];
        return fileTypeHash[ext.toLocaleLowerCase()] || '#iconweizhiwenjian'
      } else if (type === 'folder') {
        return '#iconwenjianjia3'
      }
      return '#iconweizhiwenjian'
      }
      
    }
    return {
      wordText,
      hanleEditor,
      handleCommand,
      handleChange,
      handlePathChange,
      hanleDown,
      hanleBack,
      doCopy,
      getFileSvgIcon,
      hanleFull
    }
  },
})
</script>
<style lang="less" scoped>
  .right-sider-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    
    .right-sider-header{
      height: 50px;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: space-between;
      padding-top: 16px;
      .icon{

        margin-top: 8px;
        margin-right: 12px;
      }
      h1{
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.5px;
        color: #161C1F;
      }
      .current-info{
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.4px;
        color: #A5ACB3;
      }
      .action{
        margin-right: 16px;
        display: flex;
      }
    }
    .right-sider-warp{
      flex-grow: 1;
      height: 10px;
    }
    #copyInput{
      position: absolute;
      visibility: hidden;
    }
  }
</style>