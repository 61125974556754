<template>
  <div :ref="(el) => { warpRef = el }" class="richtext-shower-container">
    <div class="richtext-shower-warp">
      <Editor
        :init="setting"
        :value="value"
        :disabled="true"
        @input="handleInput"
        />
    </div>
    <div class="dagang-container" :style="`width: ${ showDaGang ? '' : '30px' }`">
      <div class="dagang-header">
        <p class="title-p">
          <span>
            <i class="iconwangpan-liebiao1x iconfont" style="cursor: pointer;margin-right: 10px" @click="showDaGang = !showDaGang" />
          </span>
        </p>
      </div>
      <div class="dagang-warp">
        <div v-for="(item, index) in dagangList" :key="index" class="dagang-item" :class="{active: active == index}" :style="dealItemStyle(item)" @click="handleAnchor(item)">
          <p class="dagang-title" v-if="showDaGang" >{{ item.text }}</p>
          <p v-else>
            &nbsp;
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Editor from '@tinymce/tinymce-vue'
import { defineComponent, onBeforeUnmount, ref, watch } from 'vue'
import { formRequest } from "@/apis/data/form";
import style from './style'
export default defineComponent({
  components: {
    Editor,
  },
  props: {
    value: {
      type: String,
      default: '',
      require: true
    }
  },
  setup(props, ctx) {
    let editContainer = ref(null)
    const showDaGang = ref(true)
    const editorRef = ref(null)
    const setting = ref({
      language: 'zh_CN', // 中文需要请求
      menubar: false, // 禁用菜单栏
      auto_focus: true, // 自动获取焦点
      plugins: 'print preview searchreplace fullscreen autolink image imagetools link media code codesample table charmap hr pagebreak nonbreaking advlist lists textpattern help paste',
      toolbar: false,
      placeholder: '请输入正文',
      branding: false,
      elementpath: false,
      statusbar: false,
      autosave_ask_before_unload: false,
      content_style: `
    body{
      overflow: hidden;
      max-width: 790px;
      margin-left: 10%;
    }
    @media all and (max-width: 1300px) {
      body{
        margin: 0;
      } 
    }
    img{
      width: 100%;
      height: auto;
    }` + style,
      formats: {
        h1: { block: 'h1', classes: 'aiming-point' },
        h2: { block: 'h2', classes: 'aiming-point' },
        h3: { block: 'h3', classes: 'aiming-point' },
        h4: { block: 'h4', classes: 'aiming-point' },
        h5: { block: 'h5', classes: 'aiming-point' }
      },
      font_formats: '微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;' +
      '苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;' +
      'Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;' +
      'Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;' +
      'Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;' +
      'Terminal=terminal,monaco;Times New Roman=times new roman,times;Verdana=verdana,geneva;Webdings=webdings;' +
      'Wingdings=wingdings,zapf dingbats;' +
      '知乎配置=BlinkMacSystemFont, Helvetica Neue, PingFang SC, Microsoft YaHei, ' +
      'Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei, sans-serif;' +
      '小米配置=Helvetica Neue,Helvetica,Arial,Microsoft Yahei,Hiragino Sans GB,Heiti SC,WenQuanYi Micro Hei,sans-serif',
      fontsize_formats: '12px 14px 16px 18px 24px 36px 48px 56px 72px',
      // 图片上传
      images_upload_handler: function (blobInfo, success, failure) {
        if (blobInfo.blob().size / 1024 / 1024 > 5) {
          failure('上传失败，图片大小请控制在 5M 以内')
        } else {
          const data = new FormData()
          data.append('file', blobInfo.blob())
          const headers = {
            'Content-Type': 'multipart/form-data',
            metadatatoken: process.env.VUE_APP_METATOKEN
          }
          formRequest('post','/api/mapi?__method_name__=file', data, headers).then(res => {
            if (res.data.code === 200) {
              success(res.data.data.url.replace('_thumb', ''))
            } else {
              failure('上传失败')
            }
          })
        }
      },
      paste_data_images: true, // 能否粘贴dataUrl图片
      imagetools_cors_hosts: ['dwh_api.bimcc.net'],
      // setup 添加大纲按钮
      init_instance_callback: (editor) => {
        setTimeout(() => {
          setEditorHeight(editor)
          editContainer.value = editor.iframeElement.contentWindow.document.documentElement
          dealHandleScorll(editor.dom.doc)
        }, 1)
      }
    })
    const setEditorHeight = (editor) => {
      editorRef.value = editor
      setTimeout(() => {
        editor.editorContainer.style.height = (editor.iframeElement.contentDocument.body.clientHeight) + 'px'
        editor.iframeElement.style.height = (editor.iframeElement.contentDocument.body.clientHeight) + 'px'
        initIframeObserve()
      }, 100)
    }
    // 监听盒子大小变化
    let resizeObserver = null
    const initIframeObserve = () => {
      if(ResizeObserver) {
        resizeObserver = new ResizeObserver((entries) => {
          for (let entry of entries) {
            editorRef.value.iframeElement.style.height = entry.target.clientHeight+50 + 'px'
            editorRef.value.editorContainer.style.height = entry.target.clientHeight+50 + 'px'
          }
        })
        resizeObserver.observe(editorRef.value.iframeElement.contentDocument.body)
      }
    }
    onBeforeUnmount(() => {
      if(resizeObserver) {
        // 移除所有监听
        resizeObserver.disconnect()
      }
    })
    // 处理滚动
    const active = ref(0)
    const warpRef = ref(null)
    const dealHandleScorll = (container) => {
      // 需要监听父级的滚动
      if(warpRef.value && warpRef.value.parentElement && warpRef.value.parentElement.parentElement) {
        warpRef.value.parentElement.parentElement.addEventListener('scroll', (e) => {
          const elList = dagangList.value.map(el => {
            return container.querySelectorAll('.aiming-point')[el.index] ? container.querySelectorAll('.aiming-point')[el.index].offsetTop : 0
          })
          if (elList.length) {
            const elDataList = elList.map((el, index) => {
              return { offsetTop: el, index }
            })
            elDataList.reverse()
            for (let i = 0; i < elDataList.length; i++) {
              if (e.target.scrollTop >= elDataList[i].offsetTop) {
                active.value = elDataList[i].index
                break
              }
            }
          }
        })
      }
    }
    const dealItemStyle = (item) => {
      const leave = Number(item.leave) > 0 ? Number(item.leave) - 1 : Number(item.leave)
      return `padding-left: ${(leave * 15) + 10}px; box-sizing: border-box;`
    }
    const handleAnchor = (item) => {
      if(warpRef.value && warpRef.value.parentElement && warpRef.value.parentElement.parentElement) {
        const warp = warpRef.value.parentElement.parentElement
        const container = editContainer.value
        const target = container.querySelectorAll('.aiming-point').length ? container.querySelectorAll('.aiming-point')[item.index] : null
        if (target) {
          const distance = target.offsetTop - warp.scrollTop
          let i = 10
          const step = distance / 10
          let timer = null
          timer = setInterval(() => {
            i -= 1
            if (i < 0) {
              clearInterval(timer)
              return
            }
            warp.scrollTop += step
          }, 10)
        }
      }
    }
    const dagangList = ref([])
    const dealLeave = (text) => {
      const regLeave = /[\d]/i
      const res = text.match(regLeave)
      if (res) {
        return res[0]
      } else { return '' }
    }
    const element = document.createElement('p')
    const dealText = (text) => {
      element.innerHTML = text
      return element.innerText || ''
    }
    let timer = null
    const dealDagang = (html) => {
      if (timer) {
        clearTimeout(timer)
      }
      timer = setTimeout(() => {
        const regH = /<h[1-5][^>]+(.|\n)+?<\/h[1-5]>/gi
        const Harr = html.match(regH)
        let list = []
        if (Harr) {
          list = Harr.map((el, index) => {
            const data = {}
            data.leave = dealLeave(el)
            data.index = index
            data.text = dealText(el)
            return data
          })
        }
        dagangList.value = list
        timer = null
      }, 500)
    }
    watch(() => props.value, (n) => {
      if(n) {
        dealDagang(n)
      }
    }, { immediate: true })
    // 输入hander
    const handleInput = (res) => {
      ctx.emit('input', res)
      dealDagang(res)
    }
    return {
      warpRef,
      handleInput,
      setting,
      showDaGang,
      dagangList,
      dealItemStyle,
      active,
      handleAnchor
    }
  }
})
</script>

<style lang="less" scoped>
  .richtext-shower-container{
    position: relative;
    .richtext-shower-warp{
      background: none;
      width: 100%;
      :deep(.tox-tinymce) {
        overflow: hidden;
        height: auto;
        border: none;
        .tox-toolbar__primary{
          justify-content: center;
        }
        .tox-sidebar-wrap{
          width: 100%;
        }
      }
    }
    .dagang-container{
      position: fixed;
      top: 120px;
      right: 10px;
      display: block;
      width: 300px;
      background: #fff;
      z-index: 102;
      border-radius: 5px;
      transition: all .2s;
      @media all and(max-width: 1200px) {
        & {
          width: 30px;
          box-shadow: 0px 3px 6px 3px #ccc;
          .dagang-title{
            width: 0px !important;
            overflow: hidden;
          }
          &:hover{
            width: 300px;
            .dagang-title{
              width: 100% !important;
              overflow: hidden;
            }
          }
        };
      }
      .dagang-header{
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0px;
        box-sizing: border-box;
        .title-p{
          width: 100%;
          overflow: hidden;
        }
      }
      .dagang-warp{
        max-height: 500px;
        overflow-y: auto;
        overflow-x: hidden;
        .dagang-item{
          width: 100%;
          padding-top: 10px;
          padding-bottom: 10px;
          box-sizing: border-box;
          border-left: 3px solid #ddd;
          .dagang-title{
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
        .active{
          color: rgb(52, 143, 228);
          border-left: 3px solid rgb(52, 143, 228);
        }
      }
    }
  }
</style>