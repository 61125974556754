<template>
  <div class="folder-viewer-container">
    <div class="info-warp">
      <div class="info">
        <div class="cover">
          <svg aria-hidden="true" width="200" height="200">
            <use xlink:href="#iconwenjianjia1" />
          </svg>
        </div>
        <div class="text-info">
          <p class="name">{{ data.name }}</p>
        </div>
      </div>
      <div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import { Empty } from 'element-ui'
export default defineComponent({
  components: {
    'el-empty': Empty
  },
  props: {
    data: {
      type: Object,
      default() {
        return {}
      }
    }
  }
})
</script>
<style lang="less" scoped>
.folder-viewer-container{
  width: 100%;
  height: 100%;
  overflow: auto;
  .info-warp{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .info{
      flex-grow: 1;
      width: 10px;
      display: flex;
      align-items: center;
      .cover{
        width: 200px;
        height: 200px;
        border-radius: 4px;
      }
      .text-info{
        margin-left: 20px;
        height: 140px;
        padding: 20px 0;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        .name{
          font-size: 1.2em;
          font-weight: 600;
        }
      }
    }
  }
  .dynamic-warp{
    margin-top: 12px;
    border-top: 1px solid #f1f1f1;
    .type-title{
      padding: 10px;
      font-size: 1.5em;
    }
    .operation-warp{
      display: flex;
      align-items: center;
      .operation-span{
        margin-left: 10px;
      }
    }
    .pagination-warp{
      padding: 10px;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>