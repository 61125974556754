<template>
  <div class="knowledge-viewer-container">
    <div class="info-warp">
      <div class="info">
        <div class="cover" :style="`background-image: url(${Array.isArray(data.cover) && data.cover.length ? data.cover[0].url : ''})`">
          <svg t="1661140683420" class="fl-icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="30027" width="24" height="24"><path d="M832 96a64 64 0 0 1 64 64v744.32a32 32 0 0 1-48.128 27.584L512 736l-335.872 195.904A32 32 0 0 1 128 904.32V160a64 64 0 0 1 64-64h640z m0 64H192v688.576l303.872-177.28a32 32 0 0 1 32.256 0L832 848.64V160zM672 320v64h-320V320h320z" fill="#fff" p-id="30028"></path></svg>
        </div>
        <div class="text-info">
          <p class="name">{{ data.name }}</p>
          <p class="desc">{{ data.desc }}</p>
        </div>
      </div>
    </div>
    <div class="assistant-warp">
      <div class="type-title">协作人员 ( {{ data?.users?.length || 0 }} )</div>
      <div class="users-warp" v-if="Array.isArray(data.users) && data.users.length">
        <div class="user-item" v-for="(item, index) in data.users" :key="index">
          <div class="user-info">
            <el-avatar :size="40">{{ item.name[0] }}</el-avatar>
            <p class="user-name">{{ item.name }}</p>
          </div>
        </div>
      </div>
      <el-empty v-else description="暂无内容" />
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import { Empty, Avatar } from 'element-ui'
export default defineComponent({
  components: {
    'el-empty': Empty,
    'el-avatar': Avatar
  },
  props: {
    data: {
      type: Object,
      default() {
        return {}
      }
    }
  },
})
</script>
<style lang="less" scoped>
.knowledge-viewer-container{
  width: 100%;
  height: 100%;
  overflow: auto;
  .info-warp{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0;
    box-sizing: border-box;
    .info{
      flex-grow: 1;
      width: 10px;
      display: flex;
      align-items: center;
      .cover{
        width: 300px;
        height: 200px;
        border-radius: 4px;
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        .fl-icon{
          position: absolute;
          top: 10px;
          left: 10px;
        }
      }
      .text-info{
        margin-left: 20px;
        height: 200px;
        padding: 20px 0;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        .name{
          font-size: 1.5em;
          font-weight: 600;
        }
        .desc{
          margin-top: 24px;
          color: #999;
          width: 400px;
        }
      }
    }
  }
  .assistant-warp{
    margin-top: 12px;
    border-top: 1px solid #f1f1f1;
    .type-title{
      padding: 10px;
      font-size: 1.5em;
    }
    .users-warp{
      display: flex;
      flex-wrap: wrap;
      .user-item{
        flex-basis: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        .user-info{
          text-align: center;
          .user-name{
            margin-top: 5px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }
  .dynamic-warp{
    margin-top: 12px;
    border-top: 1px solid #f1f1f1;
    .type-title{
      padding: 10px;
      font-size: 1.5em;
    }
    .file-name-warp{
      display: flex;
      align-items: center;
      .file-name{
        margin-left: 10px;
      }
    }
    .operation-warp{
      display: flex;
      align-items: center;
      .operation-span{
        margin-left: 10px;
      }
    }
    .pagination-warp{
      padding: 10px;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>