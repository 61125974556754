<!--
 * @Author: 朱廷果 1028509503@qq.com
 * @Date: 2023-01-12 15:29:33
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2023-01-17 09:40:12
 * @FilePath: \dataview-next\src\manage-views\components\share\Index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <!-- todo 所有分享走这头 需要传特殊标识 来走不同的页面 -->
  <div class="wrapper">
    <Knowledge></Knowledge>
  </div>
</template>

<script>
import Knowledge from './knowledge/index.vue';
export default {
  components: {Knowledge},

};
</script>
<style lang="less" scoped>
.wrapper{
  width: 100%;
  height: 100%;
}
</style>